/* as an object, instead of an array, because it 
takes less ressources to look for a key in an objects 
rather that find an elementin an array */

export const forbiddenUsername = {
  recoverpwd: 1,
  emailverify: 1,
  info: 1,
  look: 1,
  looks: 1,
  item: 1,
  items: 1,
  profile: 1,
  editsettings: 1,
  rewaer: 1,
  admin: 1,
};
