// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../img/lock.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".newPassword__leftPanel {\n  width: 40vw;\n  height: 100vh;\n  display: inline-block;\n  vertical-align: top;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n}\n\n.newPassword__rightPanel {\n  width: 60vw;\n  height: 100vh;\n  display: inline-block;\n  vertical-align: top;\n  background-color: white;\n}\n\n@media (max-width: 800px) {\n  .newPassword__leftPanel {\n    width: 0vw;\n  }\n  .newPassword__rightPanel {\n    width: 100vw;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: cover;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/NewPassword/NewPassword.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,yDAA6C;EAC7C,4BAA4B;EAC5B,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,YAAY;IACZ,yDAA6C;IAC7C,4BAA4B;IAC5B,2BAA2B;IAC3B,sBAAsB;EACxB;AACF","sourcesContent":[".newPassword__leftPanel {\n  width: 40vw;\n  height: 100vh;\n  display: inline-block;\n  vertical-align: top;\n  background-image: url(\"./../../img/lock.jpg\");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n}\n\n.newPassword__rightPanel {\n  width: 60vw;\n  height: 100vh;\n  display: inline-block;\n  vertical-align: top;\n  background-color: white;\n}\n\n@media (max-width: 800px) {\n  .newPassword__leftPanel {\n    width: 0vw;\n  }\n  .newPassword__rightPanel {\n    width: 100vw;\n    background-image: url(\"./../../img/lock.jpg\");\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: cover;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
